<template>
  <ul class="actions items cul">
    <li class="item">
      <HeaderActionSearch />
    </li>
    <li class="item">
      <HeaderActionCart />
    </li>
    <li class="item">
      <HeaderActionProfile />
    </li>
    <li class="item">
      <HeaderActionMobileMenuToggle />
    </li>
  </ul>
</template>
<style lang="scss" scoped>
.actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  translate: calc((var(--button-action-width) - 24px) / 2);
}
</style>
